import * as React from "react"
import { Helmet } from "react-helmet"

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  // FacebookMessengerShareButton,
  // FacebookMessengerIcon,
  // HatenaShareButton,
  // InstapaperShareButton,
  // LineShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  // LivejournalShareButton,
  // MailruShareButton,
  // OKShareButton,
  // PinterestShareButton,
  PocketShareButton,
  PocketIcon,
  // RedditShareButton,
  TelegramShareButton,
  TelegramIcon,
  // TumblrShareButton,
  TwitterShareButton,
  TwitterIcon,
  // ViberShareButton,
  // VKShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  // WorkplaceShareButton
} from "react-share"

import Titre from "../components/titre"
import Navigation from "../components/navigation"

import "../styles/styles.css"
import Footer from "../components/footer"
import Newsletter from "../components/newsletter"
import Seo from "../components/seo"
import Layout from "../components/layout"

// markup
const InscriptionPage = () => {
  const shareUrl = "https://ensemble-go.fr/inscription"
  const title = "Inscription - Ensemble Grand Ouest"
  const encodedUrl = encodeURIComponent(shareUrl)
  const roundShareIcon = true

  return (
    <Layout>
      <Helmet>
        <title>Inscription - Ensemble Grand Ouest</title>
      </Helmet>
      <Seo title="Inscription - Ensemble Grand Ouest"></Seo>
      {/* <header className="header">
        <Titre></Titre>
        <Navigation active="inscription"></Navigation>
      </header>
      <main> */}
      <section className="section feuille-conteneur">
        <div className="feuille">
          <h2>Formulaire</h2>
          <p style={{ display: "flex", justifyContent: "center" }}>
            <iframe
              src="https://widget.weezevent.com/ticket/E925426/?code=45205&locale=fr-FR&width_auto=1&color_primary=00AEEF"
              width="650"
              height="600"
            ></iframe>
          </p>
          <div>
            <p style={{ textAlign: "center" }}>
              Partager sur les réseaux sociaux :<br />
              <TelegramShareButton
                url={shareUrl}
                title={title}
                className="Demo__some-network__share-button"
              >
                <TelegramIcon size={32} round={roundShareIcon} />
              </TelegramShareButton>{" "}
              <WhatsappShareButton
                url={shareUrl}
                title={title}
                separator=":: "
                className="Demo__some-network__share-button"
              >
                <WhatsappIcon size={32} round={roundShareIcon} />
              </WhatsappShareButton>{" "}
              <FacebookShareButton
                url={shareUrl}
                quote={title}
                className="Demo__some-network__share-button"
              >
                <FacebookIcon size={32} round={roundShareIcon} />
              </FacebookShareButton>{" "}
              <TwitterShareButton
                url={shareUrl}
                title={title}
                className="Demo__some-network__share-button"
              >
                <TwitterIcon size={32} round={roundShareIcon} />
              </TwitterShareButton>{" "}
              <LinkedinShareButton
                url={shareUrl}
                className="Demo__some-network__share-button"
              >
                <LinkedinIcon size={32} round={roundShareIcon} />
              </LinkedinShareButton>{" "}
              <EmailShareButton
                url={shareUrl}
                subject={title}
                body="body"
                className="Demo__some-network__share-button"
              >
                <EmailIcon size={32} round={roundShareIcon} />
              </EmailShareButton>{" "}
              <PocketShareButton
                url={shareUrl}
                title={title}
                className="Demo__some-network__share-button"
              >
                <PocketIcon size={32} round={roundShareIcon} />
              </PocketShareButton>{" "}
            </p>
            <h2>Présentation</h2>
            <p>
              Le Collectif <b>Ensemble Grand Ouest</b> vous convie à sa
              troisième rencontre à Vannes (Sulniac), du{" "}
              <b>10 au 12 février 2023</b> pour un grand temps de communion et
              festivités fraternelles.
            </p>
            <p>
              <b>Ensemble</b>, ravissons nous des merveilles et grâces que le
              Seigneur veut donner à ses Bien - Aimés. <b>Ensemble</b>, laissons
              le Coeur du Père nous conduire dans des temps de prières
              d'intercessions et de proclamations pour l'Eglise rassemblée.
            </p>
            <p>
              Plaise à Dieu de confirmer Sa Parole par des signes, des miracles
              et des guérisons pour Sa Gloire. Cette rencontre se vit dans la
              grâce d'Unité du Renouveau Charismatique en France.
            </p>
            <p>
              Vous êtes attendus pour cette{" "}
              <b>
                Grande Rencontre Ensemble Grand-Ouest 2023 Mission
                Vannes-Sulniac
              </b>
              . Nos invités seront{" "}
              <b>Père Baudoin Ardilliers et Pasteur Steve Djafa</b>.
            </p>
            <h2>Programme</h2>
            <h3>Vendredi 10 février 2023</h3>
            <p>
              <ul>
                <li>
                  <b>19h</b> : Accueil{" "}
                </li>
                <li>
                  <b>20h-22h30</b> : Soirée louange et guérison, un temps fort à
                  vivre Ensemble (entrée libre, inscription obligatoire sur le
                  site)
                </li>
              </ul>
            </p>
            <h3>Samedi 11 février 2023</h3>
            <p>
              <ul>
                <li>
                  <b>8h30</b> : Messe à l'église du village à Sulniac{" "}
                </li>
                <li>
                  <b>9h30</b> : Accueil{" "}
                </li>
                <li>
                  <b>10h</b> : Louange, enseignements et intercession
                </li>
                <li>
                  <b>12h30</b> : Pause déjeuner (tiré du sac)
                </li>
                <li>
                  <b>14h30</b> : Louange et enseignements
                </li>
                <li>
                  <b>18h</b> : Pause repas (tiré du sac)
                </li>
                <li>
                  <b>20h</b> : Grande veillée de louange et prière
                </li>
                <li>
                  <b>23h30</b> : Fin de la journée
                </li>
              </ul>
              Entrée libre avec libre participation par votre contribution.
            </p>
            <p>Prière des frères : une trentaine de priants seront présents.</p>
            <h3>Dimanche 12 février 2023</h3>
            <p>
              <ul>
                <li>
                  <b>9h30</b> : Messe à l'église du village{" "}
                </li>
                <li>
                  <b>10h30</b> : Louange et enseignements
                </li>
                <li>
                  <b>12h30</b> : Repas
                </li>
                <li>
                  <b>14h30 à 16h</b> : Louange, temps prophétique et envoi
                </li>
                <li>
                  <b>16h30</b> : Fin de la rencontre
                </li>
              </ul>
              Places limitées à 300 personnes.
            </p>
            <h3>Informations</h3>
            <p>
              <ul>
                <li>
                  <b>Lieu de la rencontre</b> : Salle des Fêtes Alice Milliat
                  SULNIAC (Proche Vannes 56)
                </li>
                <li>
                  <b>Hébergement</b> : Chambres individuelles avec douche au
                  Lycée Saint Joseph la salle
                  <br />
                  39 Boulevard des Îles 56000 Vannes (20 km de Sulniac 15
                  minutes en voiture, prévoir un moyen de transport)
                  <br />
                  Date limite d'inscription pour cet hebergement mardi 1er
                  février 2023
                  <br />
                  340 € par personne ce prix comprend (2 nuits et 2 petits
                  déjeuners) / tarif économique chambre individuelle avec douche
                  <br />
                  OU 25€ personne la nuit uniquement du samedi 11/02 + 1 pt dj /
                  tarif économique
                  <br />
                  Apportez vos draps ou sac de couchage et votre oreiller •
                  Départ collectif depuis le lieu de la rencontre.
                </li>
                <li>
                  <b>Pour la première fois ! Festivité Spécialité Bretonne !</b>{" "}
                  Dimanche 12 février 13h 1 Galette Saucisse + 1 verre de Cidre
                  + 1 Crêpe sucrée pour 6€.
                  <br />
                  Pensez à reserver votre part sur le site attention inscription
                  obligatoire. C'est parti pour une danse !
                </li>

                {/* <li>
                  <b>Horaires</b> : de 10h à 16h
                </li>
                <li>
                  <b>Repas</b> : tiré du sac
                </li>
                <li>
                  <b>Places limitées à 120 personnes</b>
                </li>
                <li>
                  <b>8h30</b>: Messe sur place
                </li>
                <li>
                  <b>Programme</b> :
                  <ul>
                    <li>
                      <b>Louange</b> : Hélène Goussebayle
                    </li>
                    <li>
                      <b>Enseignements</b> : Steve Djafa
                    </li>
                    <li>
                      <b>Interventions</b> : le Collectif
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Participation solidaire 15€</b>
                </li>
                <li>
                  <b>Informations itinéraires</b> :{" "}
                  <a href="https://bretagnelouange.weebly.com/contact.html">
                    https://bretagnelouange.weebly.com/contact.html
                  </a>
                </li> */}
              </ul>
            </p>
            <p>
              Pour toute demande d'information supplèmentaire n'hésitez pas à
              nous contacter à l'adresse :{" "}
              <a href="mailto:contact@ensemble-go.fr">contact@ensemble-go.fr</a>
            </p>
          </div>
        </div>
      </section>
      {/* <Newsletter></Newsletter>
      </main>
      <Footer></Footer> */}
    </Layout>
  )
}

export default InscriptionPage
